var site = window.site || {};

site.offers = site.offers || {};
site.offers.welcome15 = site.offers.welcome15 || {};
site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function () {
  return '';
};
(function ($) {
  // Primary behavior responsible for initializing the welcome15 offer logic
  Drupal.behaviors.ELB_welcome15 = {
    // Debug config
    debug: false,

    // Storage cookie name
    offerCookie: 'welcome_popup',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // Template path config
    templates: {
      form: 'loyalty_welcome_offer'
    },

    // Overlay config
    overlay: {
      pc: {
        className: 'welcome15-overlay',
        transition: 'none',
        width: '700px',
        height: '435px'
      },
      mobile: {
        className: 'welcome15-overlay',
        width: '100%',
        height: 'auto',
        opacity: '0',
        transition: 'none'
      }
    },

    isStr: function (str) {
      return typeof (str === 'string');
    },

    isFunc: function (func) {
      return typeof (func === 'function');
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally
     * interpolate the html using Mustache
     */

    getTemplateContent: function (key, data) {
      // Sanitize the incoming data
      var theData = data || false;
      // Get the html content of the template
      var html = $("script.inline-template[path='" + key + "']").html();
      var enablePopup;
      var self = this;

      enablePopup = $(html).attr('data-enable-popup');
      if (enablePopup === 'free-shipping') {
        self.overlay.mobile.height = '215px';
        self.overlay.pc.width = '500px';
        self.overlay.pc.height = '225px';
      }
      if (html.length < 1) {
        return $();
      }
      // If there's no data object passed in, then just return a basic jquery element
      if (theData === false || !$.isFunction(site.template.render)) {
        return $(html);
      }

      /**
       * We have html and data, which means we should use Mustache to render the output
       * return $(Mustache.render(html, data));
       */
      return site.template.render(html, theData);
    },

    // Open a colorbox window
    overlayOpenWindow: function (content, callBacks) {
      var self = this;
      var theContent = self.isObj(content) ? content : $();
      var args = {};
      var darg = {};
      var k;
      var func;

      // Dumb trick to get the entire contents of the html out of the jquery object
      theContent = theContent.wrap('<div />').parent().html();
      args = { html: theContent };
      darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (k in callBacks) {
          func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }
      $.colorbox(args);
    },
    // Show the signup form
    showSignupForm: function (timeout) {
      var undef;
      var self = this;
      var delay = timeout !== undef ? timeout : self.defaultTimeout;
      var isLoyalty;
      var content;
      var signed_in;
      var daysCount;
      var startDate;
      var currentDate;
      var diffTime;
      var diffDays;
      var enablePopup;
      var backgroundImage;
      var popupContent;

      setTimeout(function () {
        content = self.getTemplateContent(self.templates.form);
        enablePopup = $(content).attr('data-enable-popup');
        daysCount = $(content).attr('data-days-count');
        startDate = new Date($(content).attr('data-start-date'));
        popupContent = $('.js-' + enablePopup + '-welcome-popup', content);
        currentDate = new Date();
        diffTime = Math.abs(currentDate.getTime() - startDate.getTime());
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        isLoyalty = Number(site.userInfoCookie.getValue('is_loyalty_member'));
        signed_in = Number(site.userInfoCookie.getValue('signed_in'));
        backgroundImage = $('body').hasClass('device-mobile') ? $(popupContent).data('mobile-bg') : $(popupContent).data('pc-bg');

        self.setLoyaltyBgImage(enablePopup, popupContent, backgroundImage);

        if (isLoyalty && signed_in) {
          $('.welcome-offer-popup-wrapper__button', content).addClass('hidden');
        }
        if (daysCount >= diffDays) {
          self.overlayOpenWindow(popupContent, {});
        }
      }, delay);
    },

    setLoyaltyBgImage: function (enablePopup, popupContent, backgroundImage) {
      var self = this;
      var popupWidth = $('body').hasClass('device-mobile') ? self.overlay.mobile.width : self.overlay.pc.width;
      var popupHeight = $('body').hasClass('device-mobile') ? self.overlay.mobile.height : self.overlay.pc.height;

      if (enablePopup === 'loyalty' && backgroundImage) {
        popupContent.css('background-image', 'url(' + backgroundImage + ')');
        popupContent.width(popupWidth);
        popupContent.height(popupHeight);
      }
    },

    setPopupCookie: function () {
      var cookieName = this.offerCookie;

      $.cookie(cookieName, '1', {
        expires: 14,
        path: '/'
      });
    },

    /**
    * Helper function to determine if the welcome15 offer should appear on this page
    * @TODO: this will likely be expanded to be disabled on checkout and whatnot
    * @returns {boolean} whether welcome15 offer should appear on this page
    */
    showOfferOnPage: function () {
      var self = this;
      var cookieName = self.offerCookie;
      var hasCookie = $.cookie(cookieName);
      var showOffer = false;
      var settings = Drupal.settings.common || {};
      /**
       * There is a div in the mustache that gets printed based on the
       * disabled checbox input welcome15_offer_signup_v1
       */
      var markUp = self.getTemplateContent(self.templates.form);
      var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

      if (!hasCookie) {
        // Set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          self.setPopupCookie();

          return false;
        }
      }

      if ((settings.is_hub && $.cookie('LOCALE') && !hasCookie) || (!settings.is_hub && !hasCookie) || self.debug) {
        showOffer = true;
      }

      return showOffer;
    },

    showLoyaltyMessageOfferBar: function (context, data, loyaltyPoint) {
      var fname = Boolean(data.first_name) === true ? data.first_name : 'Devotee';

      $('.js-offer-bar-anonymouse-lyl-msg', context).addClass('hidden');
      $('.js-offer-bar-authendicate-lyl-msg', context).removeClass('hidden');
      $('.js-offer-bar-authendicate-lyl-msg .js-lyl-user', context).text(fname);
      $('.js-offer-bar-authendicate-lyl-msg .js-lyl-pts', context).text(loyaltyPoint);
    },

    // Main function to get things kicked off
    attach: function (context) {
      var self = this;
      var loyaltyLevelName = site.userInfoCookie.getValue('loyalty_level_name');
      var loyaltyPoint = site.userInfoCookie.getValue('current_available');
      var loyaltyPointText = $('.site-header__menu__mobile-util-links--tier-level', context).text();

      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
        this.showSignupForm();
        // Create the cookie
        self.setPopupCookie();
      }

      // eslint-disable-next-line no-unused-vars
      $(document).on('user.loaded', function (e, data) {
        var loyaltyPoints;

        if (data.is_loyalty_member) {
          loyaltyPoints = loyaltyPoint ? loyaltyPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ',') : 0;
          self.showLoyaltyMessageOfferBar(context, data, loyaltyPoints);
        }
        if (site.client.isMobile && data.signed_in) {
          $('.site-header__menu__mobile-util-links--greeting-text', context).removeClass('hidden');
          if (data.first_name) {
            $('.site-header__menu__mobile-util-links--greeting-text span', context).text(data.first_name);
          }
          if (data.is_loyalty_member) {
            $('.site-header__menu__mobile-util-links--loyalty-join', context).addClass('hidden');
            $('.site-header__menu__mobile-util-links--tier-level', context).text(loyaltyPoints + '\xa0' + loyaltyPointText + '\xa0' + loyaltyLevelName.toUpperCase()).removeClass('hidden');
          }
        }
      });
    }
  };
})(jQuery);
